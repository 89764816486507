import React from "react";
import Container from "react-bootstrap/Container";
import Card from 'react-bootstrap/Card';
import { HomeText } from "./HomeText";

export const Home = () => {
  return (
    <div>
      <header className="xApp-header">
        <Container fluid>
          <Card className="mb-3 border-0">
            {/* <Card.Img src="/images/elderly-care-by-female-caregiver.jpg"/> */}
            <Container fluid>
              <Card.Img src="/images/elderly-care-by-female-caregiver.jpg" style={{ height: '60vh', display: 'flex', width: '100%', objectFit: 'cover'}} />
            </Container>
          </Card>

          <Card
            className="mb-3 border-0"
            style={{
              width: "50rem",
              margin: "auto",
            }}
          >
            <Card.Body>
              <Card.Title style={{ textAlign: 'center', color: '#8c4234' }}>{HomeText.card1.title}</Card.Title>
              <Card.Text>{HomeText.card1.text}</Card.Text>
            </Card.Body>
          </Card>

          <Card className="mb-3 border-0" style={{
            width: "50rem",
            margin: "auto",
          }}>
            <Card.Body>
              <Card.Title style={{ textAlign: 'center', color: '#8c4234' }}>{HomeText.card2.title}</Card.Title>
              <Card.Text>{HomeText.card2.text}</Card.Text>
            </Card.Body>
          </Card>

          <Card className="mb-3 border-0" style={{
            width: "50rem",
            margin: "auto",
          }}>
            <Card.Body>
              <Card.Title style={{ textAlign: 'center', color: '#8c4234' }}>{HomeText.card3.title}</Card.Title>
              <Card.Text>{HomeText.card3.text}</Card.Text>
            </Card.Body>
          </Card>

          <Card className="mb-3 border-0" style={{
            width: "50rem",
            margin: "auto",
          }}>
            <Card.Body>
              <Card.Title style={{ textAlign: 'center', color: '#8c4234' }}>{HomeText.card4.title}</Card.Title>
              <Card.Text>{HomeText.card4.text}</Card.Text>
            </Card.Body>
          </Card>
        </Container>
      </header>
    </div>
  );
};

//<Card.Title>Bonzer Care: where care meets excellence!</Card.Title>
// className="d-flex align-items-center"
