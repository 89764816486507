import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import classes from "./Footer.module.css";

export const Footer: React.FC = (): JSX.Element => {
  return (
    <div className={classes.footer}>
      <Container fluid>
        <Row>
          <Col className="text-center mb-4 mt-4">Copyright &copy; Bonzer Care</Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col lg='1' className="text-center  mb-2"><Image src="/images/social-media/facebook.png"/></Col>
          <Col lg='1' className="text-center  mb-2"><Image src="/images/social-media/instagram.png"/></Col>
          <Col lg='1' className="text-center  mb-2"><Image src="/images/social-media/x-formerlyTwitter.png"/></Col>
          <Col lg='1' className="text-center  mb-2"><Image src="/images/social-media/linked-in.png"/></Col>
        </Row>
        <Row>
          <Col className="text-center mb-4 mt-4"><Link to='/privacy-policy'>Privacy Policy</Link></Col>
        </Row>
      </Container>
    </div>
  );
};
