import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Layout } from "./pages/Layout";
import { Home } from "./pages/Home";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { ScrollToTop } from "./components/UI/ScrollToTop";

const App = () => {
  return (
    <Router>
    <ScrollToTop>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        </Route>
      </Routes>
    </ScrollToTop>
    </Router>
  );
};
export default App;