import React from "react";
import Container from "react-bootstrap/Container";
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import { PrivacyPolicyText } from "./PrivacyPolicyText";

export const PrivacyPolicy = () => {
  return (
    <div className="app">
      <header className="xApp-header">
        <Container fluid>
          <Card className="mb-3 border-0" style={{
            width: "40rem",
            margin: "auto",
          }}
          >
            <Card.Body>
              <Card.Title style={{ textAlign: 'center', color: '#8c4234', fontSize: '40px' }}>{PrivacyPolicyText.card1.title}</Card.Title>
              <Card.Text style={{ textAlign: 'center', color: '#8c4234' }}>{PrivacyPolicyText.card1.text}</Card.Text>
            </Card.Body>
          </Card>
          <Card className="mb-3 border-0" style={{
            width: "50rem",
            margin: "auto",
          }}>
            <Card.Body>
              <Card.Text>{PrivacyPolicyText.card2.text}</Card.Text>
            </Card.Body>
          </Card>

          <Card className="mb-3 border-0" style={{
            width: "50rem",
            margin: "auto",
          }}>
            <Card.Body>
              <Card.Title style={{ textAlign: 'center', color: '#8c4234' }}>{PrivacyPolicyText.card3.title}</Card.Title>
              <Card.Text>{PrivacyPolicyText.card3.text}</Card.Text>
            </Card.Body>
          </Card>

          <Card className="mb-3 border-0" style={{
            width: "50rem",
            margin: "auto",
          }}>
            <Card.Body>
              <Card.Title style={{ textAlign: 'center', color: '#8c4234' }}>{PrivacyPolicyText.card4.title}</Card.Title>
              <Card.Text>{PrivacyPolicyText.card4.text}</Card.Text>
            </Card.Body>
          </Card>

          <Card className="mb-3 border-0" style={{
            width: "50rem",
            margin: "auto",
          }}>
            <Card.Body>
              <Card.Title style={{ textAlign: 'center', color: '#8c4234' }}>{PrivacyPolicyText.card5.title}</Card.Title>
              <Card.Text>{PrivacyPolicyText.card5.text} <Link to='mailto:admin@bonzercare.com'>admin@bonzercare.com</Link></Card.Text>
            </Card.Body>
          </Card>

          <Card className="mb-3 border-0" style={{
            width: "50rem",
            margin: "auto",
          }}>
            <Card.Body>
              <Card.Title style={{ textAlign: 'center', color: '#8c4234' }}>{PrivacyPolicyText.card6.title}</Card.Title>
              <Card.Text>{PrivacyPolicyText.card6.text}</Card.Text>
            </Card.Body>
          </Card>
        </Container>
      </header>
    </div >
  );
};