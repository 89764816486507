export const PrivacyPolicyText = {
    card1: {
        title: "Bonzer Care Privacy Policy",
        text: 'Last Updated: September 11, 2024'
    },
    card2: {
        text: 'Bonzer Care is committed to safeguarding the privacy of our users. We want to assure you that we do not share your personal information with third parties. This privacy policy outlines how we collect, use, and protect the information you provide to us.'
    },
    card3: {
        title: 'Information Collection:',
        text: 'We collect only the information necessary to provide and improve our services. This may include name, email address, etc. We do not sell, rent, or share this information with any third parties.'
    },
    card4: {
        title: 'How We Use Your Information:',
        text: 'The information collected is used solely for communicating with the intended party. We do not share your information with external parties for marketing or any other purposes.'
    },
    card5: {
        title: 'Your Choices: ',
        text: 'You have the right to access, correct, or delete your information. If you have any concerns or questions about your data, please contact us at '
    },
    card6: {
        title: 'Policy Changes:',
        text: 'We may update our privacy policy from time to time. Any changes will be communicated to you, and your continued use of our services implies your acceptance of the updated policy. By using our services, you agree to the terms outlined in this privacy policy.'
    }
}