import React from "react";
import { Container } from "react-bootstrap";
import { Footer } from "../components/UI/Footer";
import { Navbar } from "../components/UI/Navbar";
import { Outlet } from "react-router-dom";
import '../App.css';

export const Layout = () => {
  return (
    <div className='app'>
      {/* <div className='content'> */}
        <Navbar />
        <Container fluid>
          <Outlet />
        </Container>
      {/* </div> */}
      <Footer />
    </div>
  );
};
