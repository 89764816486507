export const HomeText = {
    card1: {
        title: "About Us",
        text: 'Bonzer Care provides home health services in the convenience of a client’s home in King and Snohomish counties. Our mission is to provide quality care to all clients based on their individual needs, in a safe and respectful manner that upholds clients’ dignity.'
    },
    card2: {
        title: 'Our Passion',
        text: 'At Bonzer Care, we strive to render home health care services according to the highest medical and ethical standards. Our professionally trained staff are always at your service to ensure you get the personalized care you deserve. Your health and being is our primary concern.'
    },
    card3: {
        title: 'Services',
        text: 'As a home care agency, in accordance with the laws of Washington state, Bonzer Care LLC will provide “non-medical services to ill, disabled, or vulnerable people with functional limitations, enabling them to maintain their highest level of independence and remain in their homes.”'
    },
    card4: {
        title: 'Care Team',
        text: 'Our team of care providers work with clients as needed. They include: Nurses, Certified Nurse Assistants & Home Health Aides, Social Workers, Therapists, Occupational, Physical, Respiratory, Speech Nutritionists / Dieticians'
    }
}