import React from "react";
import { Container, Nav, Navbar as NavbarBs } from "react-bootstrap";
// import { NavLink} from 'react-router-dom';

export const Navbar: React.FC = (): JSX.Element => {
  return (
    <NavbarBs className="bg-white shadow-sm mb-2">
      <Container>
        <NavbarBs.Brand href="/" className="brand-name">Bonzer Care</NavbarBs.Brand>
        <Nav className="me-auto">
          {/* <Nav.Link to='/' as={NavLink}>Home</Nav.Link> */}
        </Nav>
      </Container>
    </NavbarBs>
  );
};
